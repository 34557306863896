import { loadAccountPreferences } from './loadAccountPreferences';
import { loadCurrentUser } from './loadCurrentUser';
import { loadRecentProjects } from './loadRecentProjects';
import { loadUserAccounts } from './loadUserAccounts';
import { switchDefaultSubscription } from './switchDefaultSubscription';
import { switchSubscription } from './switchSubscription';

export const userShim = {
    loadCurrentUser,
    loadRecentProjects,
    switchSubscription,
    loadAccountPreferences,
    switchDefaultSubscription,
    loadUserAccounts,
};
